<template>
  <div class="nav-bar">
<el-button size="min" @click="$router.push('/dashBoard')" :class="{active: $store.state.menu.menuNames[0]== null && $route.path != '/userInfo'}">通知公告</el-button>
<el-button size="min" @click="switchNav(nav.path, nav.menuNames)" :class="{active: $route.path === nav.path}" v-for="(nav, index) in $store.state.menu.navTab" :key="nav.path">
  {{nav.label}}
  <i class="el-icon-close" @click="deleteTab(index, nav.path)"></i>
</el-button>
</div>
</template>
<script>
export default {
  name: 'dashBoard',

  data () {
    return {
      label: []
    }
  },
  methods: {
    // 点击删除一个历史记录
    deleteTab (index, tab) {
      this.$store.commit('menu/DELETE_TABS', index)
      const navTabs = this.$store.state.menu.navTab
      // 判断如果历史记录删完回到首页
      if (navTabs.length === 0) {
        this.$router.push('/dashBoard')
      } else if (
        (index === navTabs.length && tab === this.$route.path) ||
        (index === 0 && tab === this.$route.path) ||
        tab === this.$route.path
      ) {
        this.$router.replace(navTabs[navTabs.length - 1].path)
      }
    },
    // 点击历史记录跳转到对应路由上
    switchNav (path, menuNames) {
      this.$router.push(path)
      if (menuNames) {
        // 点击跳转对应面包屑
        this.$store.commit('menu/SET_MENU_INFO', menuNames)
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.nav-bar{
  overflow: hidden;
  display: flex;
background-color: #fff;
height: 40px;
align-items: center;
padding-left: 20px;
border-top: 1px solid #f1f1f1;
button{
  &.active{
    background-color:#37a372;
    border-color: white;
    color: white;
  }
  &:hover{
    background-color:#37a372;
    border-color: white;
    color: white;
  }
}
}
::v-deep .el-button{
  padding: 6px 12px;
  font-size: 12px;
border-radius: 0px;
}
</style>
