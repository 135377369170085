<template>
  <div class="hello" v-if="$store.state.info.state">
      <el-dialog title="" custom-class="dialogState"  :visible.sync="$store.state.info.state" :show-close="false">
          <el-result  :title="$store.state.info.title"
               :style="resultStyle" :subTitle="$store.state.info.subTitle">
              <!-- <template slot="subTitle" v-html="$store.state.info.subTitle"></template> -->
              <template slot="icon">
        <img v-if="$store.state.info.icon == 'warning'" src="../../assets/img/warning.gif"/>
        <img v-if="$store.state.info.icon == 'success'" src="../../assets/img/succeed.gif"/>
        <img v-if="$store.state.info.icon == 'load'" src="../../assets/img/load.gif"/>
           </template>
              <template slot="extra" v-if="$store.state.info.extra == 'delete'" >
                <el-button :loading="isLocked" type="danger" @click="onSubmit()">确定删除！</el-button>
                  <el-button type="info" @click="cancle()">取 消</el-button>
              </template>
              <template slot="extra" v-if="$store.state.info.extra == 'alter'" >
                <el-button :loading="isLocked" type="primary" @click="onSubmit()">确定修改！</el-button>
                  <el-button type="info" @click="cancle()">取 消</el-button>
              </template>
              <template slot="extra" v-if="$store.state.info.extra == 'create'" >
                <el-button :loading="isLocked" type="primary" @click="onSubmit()">确定生成！</el-button>
                  <el-button type="info" @click="cancle()">取 消</el-button>
              </template>
              <!-- 解析地址 -->
              <template slot="extra" v-if="$store.state.info.extra == 'ok'" >
                <ul style="text-align: left;margin-bottom: 10px;max-height:300px;overflow: auto;width: 100%;">
                  <!-- 遍历数据对象 -->
                  <li v-for="(value, key) in $store.state.info.data" :key="key">
                    <!-- 展示键 -->
                   <span :style="{fontWeight: '900',color:!value[0]?'red':'black'}">{{ key }}  </span>
                    <br >
                   <span style="margin-left: 20px;" v-for="(subItem, subIndex) in value" :key="subIndex">
                      {{subItem.endsWith('.') ? subItem.slice(0, -1) : subItem}}
                      <br>
                   </span>
                   <span style="color: red;margin-left: 20px;" v-if="!value[0]">解析失败</span>
                  </li>
                </ul>
                <el-button type="primary" @click="cancle()">OK</el-button>
              </template>
          </el-result>
      </el-dialog>
  </div>
</template>

<script>
import { DELDomains, delGroup, delUser, delPermissions, delRoles, batchDestroy, EITDomains, delNodes,delDNS ,batchSetHttps,eitNodes,delJournal,batchJournal,delPort,GetApiToken,ADDDomains,ADDBatchDomains} from '_api'

export default {
  name: 'aHint',
  props: ['message'],
  data: function () {
    return {
      state: true, // 批量添加显示
      isLocked: false, // 初始化时按钮未锁定
       // 动态padding值
       padding: '', // 你可以在这里设置默认的padding值
    }
  },
  computed: {
    // 使用计算属性来确保style是响应式的
    resultStyle() {
      return {
        padding: this.$store.state.info.extra != 'ok'?'30px 20px':'0px 20px !important', // 将data中的padding值应用到style中
      };
    },
  },
  watch: {
    message: {
      handler (newVal, oldVal) {
        if (oldVal === false && newVal === true) {
          this.state = newVal
        }
      },
      immediate: true
    }
  },
  methods: {
    // 关闭
    cancle () {
      this.state = false
      this.$store.dispatch('info/actionSTATE')
    },
    //  提交
    onSubmit (formName) {
      if (this.isLocked) return; // 如果按钮已锁定，则不执行任何操作
      this.isLocked = true; // 锁定按钮
      if (this.$store.state.info.name == 'DELDomains') {
        const pathToFind = '/itemUpdate/'+this.$store.state.info.data.id
        const index = JSON.parse(sessionStorage.getItem('navTab')).findIndex(item => item.path === pathToFind);
        DELDomains(this.$store.state.info.data).then(res => {
          // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '删除成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$store.commit('menu/DELETE_TABS', index)
              this.$parent.refreshGroups()
              
            })
          }
        })
      } else if (this.$store.state.info.name == 'delGroup') {
        delGroup(this.$store.state.info.data).then(res => {
          this.isLocked = false;
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '删除成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
      } else if (this.$store.state.info.name == 'delUser') {
        const pathToFind = '/UserUpdate/'+this.$store.state.info.data.id
        const index = JSON.parse(sessionStorage.getItem('navTab')).findIndex(item => item.path === pathToFind);
        delUser(this.$store.state.info.data).then(res => {
          this.isLocked = false;
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '删除成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$store.commit('menu/DELETE_TABS', index)
              this.$parent.refreshGroups()
            })
          }
        })
      } else if (this.$store.state.info.name == 'delPermissions') {
        delPermissions(this.$store.state.info.data).then(res => {
          this.isLocked = false;
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '删除成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
      } else if (this.$store.state.info.name == 'delRoles') {
        delRoles(this.$store.state.info.data).then(res => {
          this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '删除成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
      } else if (this.$store.state.info.name == 'delNodes') {
        delNodes(this.$store.state.info.data).then(res => {
          this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '删除成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
      } else if (this.$store.state.info.name == 'batchDestroy') {
        batchDestroy(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.state.info.data.ids.forEach(element =>{
        const pathToFind = '/itemUpdate/'+ element
        this.$store.commit('menu/DELETE_TABS', JSON.parse(sessionStorage.getItem('navTab')).findIndex(item => item.path === pathToFind))
        });
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '删除成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
      }else if (this.$store.state.info.name == 'batchJournal') {
        batchJournal(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '删除成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
      }  else if (this.$store.state.info.name == 'delDNS') {
        delDNS(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '删除成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
        
      } else if (this.$store.state.info.name == 'delJournal') {
        delJournal(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '删除成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
             
            })
          }
        })
      } else if (this.$store.state.info.name == 'delPort') {
      let data =  {...this.$store.state.info.data}
      delete data.type
        delPort(data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '删除成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              if(this.$store.state.info.data.type == 'admin'){
                 this.$parent.$refs.adminnode1.getPortList()
              }else{
                this.$parent.$refs.skip1.getPortList()
              }
            })
          }
        })
      }  else if (this.$store.state.info.name == 'EITDomains') {
        if (this.$store.state.info.data.HTTPS) {
          delete this.$store.state.info.data.HTTPS
        } 
        EITDomains(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '修改成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
      } else if (this.$store.state.info.name == 'eitNodes') {
          eitNodes(this.$store.state.info.data).then(res => {
             // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '修改成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
      }  else if (this.$store.state.info.name == 'batchSetHttps') {
        batchSetHttps(this.$store.state.info.data).then(res => {
          // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '修改成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
      }else if (this.$store.state.info.name == 'GetApiToken') {
          // 数据请求完成后解锁按钮
          this.isLocked = false;
        GetApiToken().then((res)=>{
        if (res.data.code === 200) {
          this.$store.dispatch('info/actionINFO', {
                  state: true, // 控制提示组件显示隐藏
                  icon: 'success', // 图标类型 success / warning / info / error
                  title: '生成成功！', // 标题
                  subTitle: '秒后刷新。', // 二级标题
                  extra: false, // 底部提交区域显示隐藏
                  delay: 1000
                }).then(() => {
                  this.$parent.$parent.$parent.$parent.$parent.$refs.componentHeader.refreshGroups()
                })
        }
      })
      }  else if (this.$store.state.info.name == 'ADDDomains') {
        ADDDomains(this.$store.state.info.data).then(res => {
          // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '添加成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
            
              this.$parent.$refs.BatchStore.batchStoreVisible = false // 关闭弹窗
              this.$parent.$refs.BatchStore.$refs.batchAdd1.close() //清除表单数据
              this.$parent.refreshGroups() // 刷新页面
            })
          }
        })
      }  else if (this.$store.state.info.name == 'ADDBatchDomains') {
        ADDBatchDomains(this.$store.state.info.data).then(res => {
          // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '添加成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$parent.$refs.BatchStore.batchStoreVisible = false// 关闭弹窗
              this.$parent.$refs.BatchStore.$refs.batchAdd2.close()//清除表单数据
              this.$parent.refreshGroups() // 刷新页面
            })
          }
        })
      }
      
      
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-result__extra{
  margin-top: 20px !important;
width: 100% !important;
}

::v-deep  .el-dialog{
margin-top: 20vh !important;
}
::v-deep .el-dialog__header{
  padding: 0px !important;
}
</style>
<style lang="scss">

/* 在 CSS 中 */
@media screen and (max-width: 767px) {
  /* 这是移动设备 */
  .dialogState{
  width: 100% !important;

}
}

@media screen and (min-width: 768px) {
  /* 这是电脑 */
  .dialogState{
  width: 500px !important;
}
}

</style>
