export default {
  namespaced: true,
  // vuex存储公共状态的文件
  state: {
    state: false, // 控制提示组件显示隐藏
    icon: 'success', // 图标类型 success  warning  info  error
    title: '', // 标题
    subTitle: '', // 二级标题
    extra: false, // 底部提交区域显示隐藏
    delay: 0, // 倒计时
    data: { }, // 需要传递的参数
    name: ''// 请求的接口名称
  },
  actions: {
    async actionINFO ({ commit }, status) {
      return new Promise((resolve, reject) => {
        commit('SET_INFO', status)
        if (status.delay) {
          setTimeout(() => {
            resolve('异步操作完成')
          }, status.delay)
        } else {
          resolve('操作完成')
        }
      })
    },
    async actionSTATE ({ commit }, status) {
      return new Promise((resolve, reject) => {
        commit('SET_STATE', status)
        resolve('点击取消了')
      })
    }
  },
  mutations: {
    SET_INFO (state, status) {
      if (status.data) {
        state.data = status.data
        state.name = status.name
      }
      state.state = status.state
      state.icon = status.icon
      state.title = status.title
      state.subTitle = status.delay ? (status.delay / 1000) + status.subTitle : status.subTitle
      state.extra = status.extra
      if (status.delay) {
        state.delay = status.delay
        const timer = setInterval(() => {
          if (state.delay > 0) {
            if (state.delay > 1000) {
              state.subTitle = ((state.delay -= 1000) / 1000) + status.subTitle
            } else {
              state.delay = 0
              state.state = false
              clearInterval(timer)
            }
          } else {
            state.state = false
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    SET_STATE (state, status) {
      state.state = false
    }
  }
}
