<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created () {
  }
}
</script>
<style>
*{
  margin: 0;
  padding: 0;
}
body{
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#app{
  width: 100%;
  height: 100%;
}
::v-deep .el-textarea__inner{
  background-color: #ffffff00 !important;
}
::v-deep .el-message {

z-index: 9999 !important;

}
.messageIndex {

z-index: 9999 !important

}
.el-form-item__error{
  position: static;
}
</style>
