export default {
  namespaced: true,
  // vuex存储公共状态的文件
  state: {
    loading: false, // 控制Loading组件的显示隐藏
    percentage: 0, // 控制进度条的进度
    // 登陆
    token: localStorage.getItem('token') || '',
    // 用户角色
    role: localStorage.getItem('role') || '',
    user_id: localStorage.getItem('user_id') ? JSON.parse(localStorage.getItem('user_id')) : ''
  },
  mutations: {
    SET_PERCENTAGE (state, status) {
      state.percentage = status
    },
    SET_LOADING (state, status) {
      state.loading = status
    },
    INIT_LOGIN (state, data) {
      // 将用户信息存储到state中
      state.token = data.token
      state.role = data.role
      state.user_id = data.user_id
      // 设置用户信息缓存
      localStorage.setItem('token', data.token)
      localStorage.setItem('role', data.role)
      localStorage.setItem('user_id', data.user_id)
    },
    LOG_OUT (state) {
      state.token = ''
      state.role = ''
      state.user_id = ''
      localStorage.removeItem('token')
      localStorage.removeItem('role')
      localStorage.removeItem('user_id')
      sessionStorage.clear()
    }
  }
}
